import React from "react";
import { useParams} from "react-router";
import Orders from './orders'
import queryString from 'query-string';
import moment from 'moment';

import { Country, State } from 'country-state-city';


import '../index.css';
import '../fonts/font.css';
import '../fonts/new/signpainter/stylesheet.css';
import '../fonts/new/tiling-gothic/stylesheet.css';

const regionOptions = ['Australia', 'China', 'International']
const stateOptions = ["VIC", "NSW", "ACT", "QLD", "WA", "SA", "NT", "TAS"]
const stepOptions = ["Unfulfilled", "Partially-fulfilled", "Personalising", "Canceled", "Fulfilled"]
const orderOptions = ["Paid", "Unpaid", "Has-personalisation", "Flagged", "POS"]
const shippingOptions = ["Standard", "Express", "Pickup"]



const queryOptions = {
  region: Country.getAllCountries().map(country => ({label: country.name, value: country.name})),
  state: [{ label: 'KY', value: 'KY' }, { label: 'VIC', value: 'Victoria' }, { label: 'NSW', value: 'New South Wales' }, { label: 'ACT', value: 'Australian Capital Territory' }, { label: 'QLD', value: 'Queensland' }, { label: 'WA', value: 'Western Australia' }, { label: 'SA', value: 'South Australia' }, { label: 'NT', value: 'Northern Territory' }, { label: 'TAS', value: 'Tasmania' }],
  status: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Awaiting Stock', value: 'awaiting-stock' }, { label: 'Partially Fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' }, { label: 'Fulfilled', value: 'fulfilled' }],
  order: [{ label: 'Paid', value: 'Paid' }, { label: 'Unpaid', value: 'Unpaid' }, { label: 'Has personalisation', value: 'Has-personalisation' }, { label: 'Flagged', value: 'Flagged' }, { label: 'POS', value: 'POS' }, { label: 'Ready to ship', value: 'ready-to-ship' }, { label: 'Partially fulfilled', value: 'partially-fulfilled'}, {label: 'Contains Pets', value: 'contains-pets'}, {label: 'Art Ready', value: 'art-ready'}, {label: 'Art Not Ready', value: 'art-not-ready'}],
  shipping: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
  pickStatus: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD'}, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
  orderSource: [{ label: 'AU Shopify', value: 'shopify_au' }, { label: 'TMALL', value: 'tmall' }, { label: 'US Shopify', value: 'shopify_us' }, { label: 'NZ Shopify', value: 'shopify_nz' }, { label: 'UK Shopify', value: 'shopify_uk' }, { label: 'QV Store', value: 'qv_store' }, { label: 'Chadstone', value: 'chadstone' }, { label: 'Greene St', value: 'greene' }, { label: 'Galeries Store', value: 'galeries_store' },{ label: 'Castle Towers', value: 'castle_towers' }, { label: 'Highpoint', value: 'highpoint' }, { label: 'Rosebery', value: 'rosebery' }, { label: 'Bondi Store', value: 'bondi' }, { label: '80 Collins', value: '80_collins' }, { label: 'HQ Store', value: 'hq_store' }, { label: 'Pitt St', value: 'pitt_st' }, { label: 'PacFair', value: 'pacfair' }, { label: 'James St', value: 'james_st' }, { label: 'Goop', value: 'goop' },  { label: 'Custom', value: 'custom' }],
  flagType: [{}],
}

const queryParams = [
  'region',
  'state',
  'status',
  'order',
  'shipping',
  'search',
  'startDate',
  'endDate',
  'pickStatus',
  'orderSource',
  'flagType',
]

const OrdersPage = ({location}) => {
  let params = queryString.parse(location.search);
  let initialFilter = {}
  queryParams.map(val => {
    const indexValue = params[val] //.replace('%20', ' ')
    if (!(['startDate', 'endDate', 'search', 'status', 'shipping'].includes(val))) {
      if (queryOptions[val].map(val => val.value).includes(indexValue)) {
        const optionValue = queryOptions[val].find(val => val.value === indexValue)
        if (indexValue && optionValue) {
          initialFilter[val] = optionValue
        }
      }
    } else if ((['status', 'shipping'].includes(val)) && indexValue !== undefined) {
      initialFilter[val] = indexValue.split(',').map(valueStep => queryOptions[val].find(val => val.value === valueStep))
    } else {
      initialFilter[val] = indexValue
    }
  })


  const initialState = Object.keys(queryParams).length > 0 ? {
    selectedRegion: initialFilter.region ? initialFilter.region : null,
    filterRegion: initialFilter.region ? initialFilter.region.value : null,
    selectedPickStatus: initialFilter.pickStatus ? initialFilter.pickStatus : null,
    filterPickStatus: initialFilter.pickStatus ? initialFilter.pickStatus.value : null,
    selectedFlagType: initialFilter.flagType ? initialFilter.flagType : null,
    filterFlagType: initialFilter.flagType ? initialFilter.flagType.value : null,
    selectedSource: initialFilter.orderSource ? initialFilter.orderSource : null,
    filterSource: initialFilter.orderSource ? initialFilter.orderSource.value : null,
    selectedState: initialFilter.state ? initialFilter.state : null,
    filterState: initialFilter.state ? initialFilter.state.value : null,
    selectedStatus: initialFilter.status ? initialFilter.status : null,
    filterStatus: initialFilter.status ? initialFilter.status.map(val => val.value) : null,
    selectedOrder: initialFilter.order ? initialFilter.order : null,
    filterOrder: initialFilter.order ? initialFilter.order.value : null,
    selectedShipping: initialFilter.shipping ? initialFilter.shipping : null,
    filterShipping: initialFilter.shipping ? initialFilter.shipping.map(val => val.value) : null,
    searchTerm: initialFilter.search || '',
    orderStartDate: initialFilter.startDate || '', //moment().subtract(1, 'week').format('YYYY-MM-DD'),
    filterStartDate: initialFilter.startDate || '', //moment().subtract(1, 'week').format('YYYY-MM-DD'),
    orderEndDate: initialFilter.endDate || '', //moment().format('YYYY-MM-DD'),
    filterEndDate: initialFilter.endDate || '', //moment().format('YYYY-MM-DD'),
    regionOptions: Country.getAllCountries().map(country => ({label: country.name, value: country.name})),
    orderSourceOptions: [{ label: 'AU Shopify', value: 'shopify_au' }, { label: 'TMALL', value: 'tmall' }, { label: 'US Shopify', value: 'shopify_us' }, { label: 'NZ Shopify', value: 'shopify_nz' }, { label: 'UK Shopify', value: 'shopify_uk' }, { label: 'QV Store', value: 'qv_store' }, { label: 'Chadstone', value: 'chadstone' }, { label: 'Greene St', value: 'greene' }, { label: 'Galeries Store', value: 'galeries_store' }, { label: 'Castle Towers', value: 'castle_towers' }, { label: 'Highpoint', value: 'highpoint' }, { label: 'Rosebery', value: 'rosebery' }, { label: 'Emporium', value: 'emporium' }, { label: 'Bondi Store', value: 'bondi' }, { label: '80 Collins', value: '80_collins' }, { label: 'HQ Store', value: 'hq_store' }, { label: 'Pitt St', value: 'pitt_st' }, { label: 'PacFair', value: 'pacfair' }, { label: 'James St', value: 'james_st' }, { label: 'Goop', value: 'goop' },  { label: 'Custom', value: 'custom' }],
    stateOptions: [{ label: 'KY', value: 'KY' }, { label: 'VIC', value: 'Victoria' }, { label: 'NSW', value: 'New South Wales' }, { label: 'ACT', value: 'Australian Capital Territory' }, { label: 'QLD', value: 'Queensland' }, { label: 'WA', value: 'Western Australia' }, { label: 'SA', value: 'South Australia' }, { label: 'NT', value: 'Northern Territory' }, { label: 'TAS', value: 'Tasmania' }],
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Awaiting Stock', value: 'awaiting-stock' }, { label: 'Partially Fulfilled', value: 'partially-fulfilled' }, { label: 'Canceled', value: 'Canceled' }, { label: 'Fulfilled', value: 'fulfilled' }],
    orderOptions: [{ label: 'Paid', value: 'Paid' }, { label: 'Unpaid', value: 'Unpaid' }, { label: 'Has personalisation', value: 'Has-personalisation' }, { label: 'Flagged', value: 'Flagged' }, { label: 'POS', value: 'POS' }, { label: 'Ready to ship', value: 'ready-to-ship' }, { label: 'Partially fulfilled', value: 'partially-fulfilled'}, {label: 'Contains Pets', value: 'contains-pets'}, {label: 'Art Ready', value: 'art-ready'}, {label: 'Art Not Ready', value: 'art-not-ready'}],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'Same Day', value: 'same-day' }, { label: 'Next Day', value: 'next-day' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD'}, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }, { label: 'LAUNCH', value: 'LAUNCH' }, { label: 'LAUNCH-MONO', value: 'LAUNCH-MONO' }, { label: 'AIRBOX', value: 'AIRBOX' }, { label: 'AIRBOX-MONO', value: 'AIRBOX-MONO' }],
    flagTypeOptions: [{}]
  } : null

  return <Orders url='/orders' location={location} initValues={initialState}/>
}

export default OrdersPage;
