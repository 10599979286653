import React, { useState, useEffect, useMemo,useCallback } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import useQuery from '../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';
import useSKUStatus from "./helpers/useSKUStatus";
import useUpcomingStock from "./helpers/useUpcomingStock";
import { debounce } from 'lodash';

// Utility functions
const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return mapToObj(map);
}

const mapToObj = (inputMap) => {
  let obj = {};
  inputMap.forEach(function (value, key) {
    obj[key] = value;
  });
  return obj;
}

// Category array
const categories = [
  'CLASSIC',
  'LIGHT',
  'TRUNK',
  'SET',
  'BAG',
  'ACCESSORIES',
  'MISC',
]


const LineRenderer = React.memo(({ line, tableData, lineIndex, pendingData, pendingDataUS, pendingDataUK, isAUExpanded, isUSExpanded, isUKExpanded, findSKUStatus, findUpcomingStock }) => {
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [tooltip, showTooltip] = useState(false);
  const handleMouseEnter = (columnIndex) => {
    setHoveredColumn(columnIndex);
  };

  const handleMouseLeave = () => {
    setHoveredColumn(null);
  };

  const renderCell = (content, columnIndex, key) => (
    <td
      key={key}
      style={{ background: hoveredColumn === columnIndex ? '#f5f5f5' : '' }}
      onMouseEnter={() => handleMouseEnter(columnIndex)}
      onMouseLeave={handleMouseLeave}
    >
      {content}
    </td>
  );

  return (
    <tr onMouseEnter={() => showTooltip(true)} onMouseLeave={() => showTooltip(false)}>
      
      { tooltip ? renderCell(
        line[0], 0, `sku-${lineIndex}-1`
      ) : renderCell(line[0], 0, `sku-${lineIndex}-1`)}
      {renderCell(
        <b>{line[1] + line[2] + line[3] + line[6] + line[7] + line[8] + line[9] + line[11] + line[15] + line[16] + line[17] + line[18] + line[19] + line[20] + line[21] + line[22] + line[23] + line[24] - (pendingData ? (pendingData[line[0]] || 0) : 0)}</b>, 1, `calculation-${lineIndex}-2`
      )}

      
      {isAUExpanded && (
        <>
          {renderCell(findSKUStatus(line[0], 'AU'), 4, `line3-${lineIndex}-5`)}
          {renderCell(
            tableData && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].auNextContainer?.date ? 
            moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].auNextContainer?.date)?.format('DD-MM-YYYY') : "", 2, `auNextContainer-${lineIndex}-3`
          )}
          {renderCell(<span className="text-danger">{pendingData && pendingData[line[0]] || 0}</span>, 3, `pendingDataAU-${lineIndex}-4`)}
          {renderCell(line[3], 4, `line3-${lineIndex}-5`)}
          {renderCell(line[6], 5, `line6-${lineIndex}-6`)}
          {renderCell(line[18], 6, `line18-${lineIndex}-7`)}
          {renderCell(line[19], 6, `line18-${lineIndex}-7`)}
          {renderCell(line[1], 7, `line1-${lineIndex}-8`)}
          {renderCell(line[2], 8, `line2-${lineIndex}-9`)}
          {renderCell(line[11], 9, `line11-${lineIndex}-10`)}
          {renderCell(line[7], 10, `line7-${lineIndex}-11`)}
          {renderCell(line[9], 11, `line9-${lineIndex}-12`)}
          {renderCell(line[15], 12, `line15-${lineIndex}-13`)}
          {renderCell(line[16], 13, `line16-${lineIndex}-14`)}
          {renderCell(line[17], 14, `line17-${lineIndex}-15`)}
          {renderCell(line[20], 15, `line17-${lineIndex}-16`)}

          {renderCell(line[21], 16, `line17-${lineIndex}-17`)}
          {renderCell(line[22], 17, `line17-${lineIndex}-18`)}
          {renderCell(line[23], 18, `line17-${lineIndex}-15`)}
          {renderCell(line[24], 18, `line17-${lineIndex}-15`)}
        </>
      )}

      {renderCell(<b>{line[5] + line[14] + line[13] + line[12] - (pendingDataUS ? (pendingDataUS[line[0]] || 0) : 0)}</b>, 15, `usCalculation-${lineIndex}-16`)}

      {isUSExpanded && (
        <>
          {renderCell(findSKUStatus(line[0], 'US'), 4, `line3-${lineIndex}-5`)}
          {renderCell(
            tableData && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')] && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].usNextContainer?.date ?
            moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].usNextContainer?.date)?.format('DD-MM-YYYY') : ''
            , 16
            , `usNextContainer-${lineIndex}-17`
          )}
          {renderCell(<span className="text-danger">{pendingDataUS && pendingDataUS[line[0]] || 0}</span>, 17, `pendingDataUS-${lineIndex}-18`)}
          {renderCell(line[14], 18, `line14-${lineIndex}-19`)}
          {renderCell(line[5], 19, `line5-${lineIndex}-20`)}
          {renderCell(line[12], 20, `line12-${lineIndex}-21`)}
          {renderCell(line[13], 21, `line13-${lineIndex}-22`)}
        </>
      )}

      {renderCell(<b>{line[10] - (pendingDataUK ? (pendingDataUK[line[0]] || 0) : 0)}</b>, 22, `ukCalculation-${lineIndex}-23`)}

      {isUKExpanded && (
        <>
          {renderCell(findSKUStatus(line[0], 'UK'), 4, `line3-${lineIndex}-5`)}
          {renderCell(
            tableData && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')] && tableData && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].ukNextContainer?.date ?
            moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].ukNextContainer?.date)?.format('DD-MM-YYYY'): "", 23, `ukNextContainer-${lineIndex}-24`
          )}
          {renderCell(<span className="text-danger">{pendingDataUK && pendingDataUK[line[0]] || 0}</span>, 24, `pendingDataUK-${lineIndex}-25`)}
          {renderCell(line[10], 25, `line10-${lineIndex}-26`)}
        </>
      )}
    </tr>
  );
});

const CategoryRow = React.memo(({ category, categoryGroup, index, expandedProducts, searchTerm, toggleProductSelected, pendingData, pendingDataUS, pendingDataUK, isAUExpanded, isUSExpanded, isUKExpanded, tableData, findSKUStatus, findUpcomingStock }) => {
  const handleCatClicked = () => {
    let allProducts = categoryGroup.map(groupedProduct => groupedProduct.product._id);
    let checker = (arr, target) => target.every(v => arr.includes(v));

    if (checker(expandedProducts, allProducts)) {
      toggleProductSelected([]);
    } else {
      toggleProductSelected(allProducts);
    }
  };

  return (
    <React.Fragment key={`cat-${category}${index}`}>
      <tr onClick={handleCatClicked} style={{ userSelect: 'none', background: 'rgb(31, 62, 60)', color: '#fff', cursor: 'pointer' }}>
        <td><b>{category}</b></td>
      </tr>
      {categoryGroup.map((groupedProduct, productIndex) => {
        const { product, lines } = groupedProduct;

        const isExpanded = expandedProducts.includes(product._id) || searchTerm !== '';

        if (lines.length === 0) return null;

       

        return (
          <React.Fragment key={`prod-${product.name}${productIndex}`}>
            <tr style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => toggleProductSelected([product._id])}>
              <td><b>{product.name}</b></td>
            </tr>
        

            {isExpanded && lines.map((line, lineIndex) => (
              <LineRenderer
                findSKUStatus={findSKUStatus}
                findUpcomingStock={findUpcomingStock}
                key={`line-${lineIndex}`}
                line={line}
                lineIndex={lineIndex}
                pendingData={pendingData}
                pendingDataUS={pendingDataUS}
                pendingDataUK={pendingDataUK}
                isAUExpanded={isAUExpanded}
                isUSExpanded={isUSExpanded}
                isUKExpanded={isUKExpanded}
                tableData={tableData}
              />
            ))}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
});

const BasicTable = () => {
  const [refresh, setRefresh] = useState(true);
  const [isAUExpanded, setAUExpanded] = useState(false);
  const [isUSExpanded, setUSExpanded] = useState(false);
  const [isUKExpanded, setUKExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');

  const refreshData = () => {
    setRefresh(prev => !prev);
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchTerm(inputValue);
      debouncedSearch.cancel(); 
    }
  };

  const findSKUStatus = useSKUStatus();
  const findUpcomingStock = useUpcomingStock();


  const [data, state] = useQuery({
    url: `/product/all-stock-v2`,
    refresh,
  });

  const [products, productState] = useQuery({
    url: `/product/findAll`,
    refresh,
  });

  const [containers, containerState] = useQuery({
    url: `/inventory/fetchUpcomingContainer/`,
    refresh,
  });

  const [tableData, setTableData] = useState({});

  useEffect(() => {
    if (products && containers) {
      const updatedTable = {};

      products.data.forEach(product => {
        product.variants.forEach(variant => {
          const sku = variant.sku;

          let usLocations = ['USA - Dallas', 'UNIS', 'LCHSLC', 'LCHKTC'];
          let ukLocations = ['Airbox UK'];

          let containerLineData = [...containers].reverse().map(container => {
            let containerLine = container.containerLines.find(containerLine => containerLine.variant.sku === sku);
            let count = containerLine == undefined ? 0 : containerLine.quantity;

            let location = container.landingLocation.name;
            let date = container.arrivalDate;
            return {
              count,
              location,
              date,
              country: usLocations.includes(location) ? 'US' : ukLocations.includes(location) ? 'UK' : 'AU'
            };
          }).filter(val => val.count !== 0);

          let usList = containerLineData.filter(val => val.country === 'US');
          let auList = containerLineData.filter(val => val.country === 'AU');
          let ukList = containerLineData.filter(val => val.country === 'UK');

          updatedTable[sku] = {
            usNextContainer: usList.length > 0 ? usList[0] : null,
            auNextContainer: auList.length > 0 ? auList[0] : null,
            ukNextContainer: ukList.length > 0 ? ukList[0] : null,
          };
        });
      });

      setTableData(updatedTable);
    }
  }, [products, containers]);

  const [groupedData, setGroupedData] = useState([]);
  const [expandedProducts, setExpandedProducts] = useState([]);

  const toggleProductSelected = (ids) => {
    setExpandedProducts(prev => {
      const newExpandedProducts = new Set(prev);
      ids.forEach(id => {
        if (newExpandedProducts.has(id)) {
          newExpandedProducts.delete(id);
        } else {
          newExpandedProducts.add(id);
        }
      });
      return Array.from(newExpandedProducts);
    });
  }

  useEffect(() => {
    if (!(data && products && groupedData && setGroupedData && expandedProducts)) {
      return;
    }

    let internalGroupedData = [];

    products.data.forEach(product => {
      let lines = [];
      product.variants.forEach(variant => {
        let foundLine = data.find(line => line[0] === variant.sku);
        if (variant.isArchived !== true && foundLine && (searchTerm === '' || foundLine[0].toUpperCase().includes(searchTerm.toUpperCase()))) {
          lines.push(foundLine);
        }
      });
      if (product.isArchived !== true && lines.length !== 0) {
        internalGroupedData.push({
          product: {
            ...product,
            categoryIndex: categories.findIndex(val => val === product.category) || 10,
          },
          lines: lines.sort((a, b) => a[0].localeCompare(b[0]))
        });
      }
    });

    internalGroupedData = internalGroupedData.filter(val => val.lines.length !== 0).sort((a, b) => a.product.name.localeCompare(b.product.name));

    const categoryGrouped = groupBy(internalGroupedData, productGroup => productGroup.product.category);

    setGroupedData(categoryGrouped);

  }, [data, products, setGroupedData, expandedProducts, searchTerm]);

  const [pendingData, pendingState] = useQuery({
    url: `/inventory/pendingStock`,
    refresh,
  });

  const [pendingDataUS, pendingUSState] = useQuery({
    url: `/inventory/pendingStockUS`,
    refresh,
  });

  const [pendingDataUK, pendingUKState] = useQuery({
    url: `/inventory/pendingStockUK`,
    refresh,
  });

  const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-');
  const csvData = useMemo(() => [
    [
      'SKU',
      'AU TOTAL',
      'CONTAINER AU',
      'PENDING AU',
      'EWE - Melbourne',
      'EWE - Sydney',
      'EWE - Brisbane',
      'EWE - Perth',
      'HQ2',
      'QV Store',
      'Chadstone',
      'Galeries Store',
      'Bondi',
      'Pacfair',
      'Pitt St',
      'James St',
      'Parramatta',
      'US TOTAL',
      'CONTAINER US',
      'PENDING US',
      'GREEEN ST',
      'UNIS',
      'KENTUCKY',
      'UTAH',
      'UK TOTAL',
      'CONTAINER UK',
      'PENDING UK',
      'Airbox',
    ],
    ...(data && pendingData && pendingDataUS && pendingDataUK ? data.map(line => [
      line[0], // SKU
      line[1] + line[2] + line[3] + line[6] + line[7] + line[8] + line[9] + line[11] + line[15] + line[16] + line[17] + line[18] + line[19]  + line[20] + line[21] + line[22] + line[23] + line[24] - (pendingData ? (pendingData[line[0]] || 0) : 0), // AU TOTAL
      tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')] && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].auNextContainer && moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].auNextContainer?.date)?.format('DD-MM-YYYY'),
      pendingData[line[0]] || 0, // PENDING AU
      line[3], // EWE - Melbourne
      line[6], // EWE - Sydney
      line[18], // EWE - Brisbane
      line[19], // EWE - Perth
      line[1], // HQ2
      line[2], // QV Store
      line[11], // Chadstone
      line[7], // Galeries Store
      line[9], // Bondi
      line[15], // Pacfair
      line[16], // Pitt St
      line[17], // James St
      line[20], // Parramatta
      line[21], // Castle Towers
      line[22], // Highpoint
      line[23], // Rosebery
      line[24], // Emporium
      line[5] + line[12] + line[13] + line[14] - (pendingDataUS ? (pendingDataUS[line[0]] || 0) : 0), // US TOTAL
      tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')] && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].usNextContainer && moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].usNextContainer?.date)?.format('DD-MM-YYYY'),
      pendingDataUS && pendingDataUS[line[0]] || 0, // PENDING US
      line[14], // GREEEN ST
      line[5], // UNIS
      line[12], // KENTUCKY
      line[13], // UTAH
      line[10] - (pendingDataUK ? (pendingDataUK[line[0]] || 0) : 0), // UK TOTAL
      tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')] && tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].ukNextContainer && moment(tableData[line[0].split('-').filter((val) => val !== 'P' && val !== 'LE').join('-')].ukNextContainer?.date)?.format('DD-MM-YYYY'),
      pendingDataUK[line[0]] || 0, // PENDING UK
      line[10] // Airbox
    ]) : [])
  ], [data, pendingData, pendingDataUS, pendingDataUK, tableData]);

  const allSelected = expandedProducts.length === products?.data?.length || searchTerm !== '';
  const toggleProductSelectedAll = () => {
    if (allSelected) {
      setExpandedProducts([]);
      setSearchTerm('');
    } else {
      setExpandedProducts(products.data.map(product => product._id));
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Inventory" breadcrumbItem="All Locations" />
        <div style={{ margin: '0 12px' }}>
          <Row style={{ background: '#fff', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
            <Col sm="2" style={{ padding: 16 }}>
              <Button type="button" color={!allSelected ? 'success' : 'warning'} className="btn-lg btn-rounded" onClick={toggleProductSelectedAll}> {!allSelected ? 'Expand All' : searchTerm !== '' ? 'Close All & Clear' : 'Close All'} </Button>
            </Col>
            <Col sm="8" style={{ paddingBottom: 8 }}>
              <div className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search, then press Enter..."
                    value={inputValue}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}      
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </Col>
            <Col sm="2" style={{ padding: 16 }}>
              <CSVLink
                style={{ float: 'right' }}
                data={csvData}
                filename={`TOTAL_STOCK_${dateString}.csv`}
              >
                <Button type="button" color="secondary" className="btn-lg btn-rounded">
                  CSV
                </Button>
              </CSVLink>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitle>Total Stock Levels</CardTitle>
                <div style={{
                  display: 'block',
                  width: '100%',
                  overflowX: 'auto',
                  overflowY: 'scroll',
                  height: 'calc(70vh)',
                  whiteSpace: 'nowrap'
                }}>
                  {data ? (
                    <Table className="table mb-0">
                      <thead style={{ position: 'sticky', top: 0, background: 'white' }}>
                        <tr>
                          <th style={{ cursor: 'default' }}></th>
                          <th onClick={() => setAUExpanded(prev => !prev)}>
                            <b>AU Avaliable</b>
                            {isAUExpanded ? (
                              <i className="bx bx-caret-right"></i>
                            ) : (
                              <i className="bx bx-caret-down"></i>
                            )}
                          </th>
                          {isAUExpanded && (
                            <>
                              <th>STATUS</th>
                              <th>CONTAINER</th>
                              <th>PENDING AU</th>
                              <th>EWE - Melbourne</th>
                              <th>EWE - Sydney</th>
                              <th>EWE - Brisbane</th>
                              <th>EWE - Perth</th>
                              <th>HQ2</th>
                              <th>QV Store</th>
                              <th>Chadstone</th>
                              <th>Galeries Store</th>
                              <th>Bondi</th>
                              <th>Pacfair</th>
                              <th>Pitt St</th>
                              <th>James St</th>
                              <th>Parramatta</th>
                              <th>Castle Towers</th>
                              <th>Highpoint</th>
                              <th>Rosebery</th>
                              <th>Emporium</th>
                            </>
                          )}
                          <th onClick={() => setUSExpanded(prev => !prev)}>
                            <b>US Avaliable</b>
                            {isUSExpanded ? (
                              <i className="bx bx-caret-right"></i>
                            ) : (
                              <i className="bx bx-caret-down"></i>
                            )}
                          </th>
                          {isUSExpanded && (
                            <>
                              <th>STATUS</th>
                              <th>CONTAINER</th>
                              <th>PENDING US</th>
                              <th>GREEEN ST</th>
                              <th>UNIS</th>
                              <th>KENTUCKY</th>
                              <th>UTAH</th>
                            </>
                          )}
                          <th onClick={() => setUKExpanded(prev => !prev)}>
                            <b>UK Avaliable</b>
                            {isUKExpanded ? (
                              <i className="bx bx-caret-right"></i>
                            ) : (
                              <i className="bx bx-caret-down"></i>
                            )}
                          </th>
                          {isUKExpanded && (
                            <>
                              <th>STATUS</th>
                              <th>CONTAINER</th>
                              <th>PENDING UK</th>
                              <th>Airbox</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((category, index) => {
                          const categoryGroup = groupedData[category];
                          if (!categoryGroup) return null;

                          return (
                            <CategoryRow
                              findSKUStatus={findSKUStatus}
                              key={`cat-${category}${index}`}
                              category={category}
                              categoryGroup={categoryGroup}
                              index={index}
                              expandedProducts={expandedProducts}
                              searchTerm={searchTerm}
                              toggleProductSelected={toggleProductSelected}
                              pendingData={pendingData}
                              pendingDataUS={pendingDataUS}
                              pendingDataUK={pendingDataUK}
                              isAUExpanded={isAUExpanded}
                              isUSExpanded={isUSExpanded}
                              isUKExpanded={isUKExpanded}
                              tableData={tableData}
                              findUpcomingStock={findUpcomingStock}
                            />
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BasicTable;
