import React, { Component, useState, useEffect } from "react";
import { Form, Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Label, Input, CardHeader, Collapse, InputGroup, InputGroupAddon, Table } from "reactstrap";
// Editable
import { useHistory } from "react-router-dom";
import superagent from 'superagent'

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useQuery from '../../../helpers/JIFI/useQuery';
import { backendUrl } from '../../../helpers/consts.js'
import { useSelector } from "react-redux";

import { CSVReader } from 'react-papaparse';
import { CSVLink, CSVDownload } from "react-csv";
import SupplyChainNav from "../../../components/supply-chain/nav.js";
import CommentHostory from "../../Skote/Dashboard/CommentHistory.js";
import ProductVariantSelector from "../helpers/ProductVariantSelector.js";


export const Accordian = ({children, title, close}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (close === true) {
      setOpen(false)
    }
  }, [close])
  return (
    <>
      <div id="accordion">
        <Card className="mb-1">
          <CardHeader onClick={() => setOpen(prev => !prev)} className="p-3" id="headingOne">
            <h6 className="m-0 font-14">
              <span
                style={{ cursor: "pointer" }} className={''}>
                {title}
              </span>
            </h6>
          </CardHeader>
          <Collapse isOpen={open}>
            <Card>
              <CardBody>
                {children}
              </CardBody>
            </Card>
          </Collapse>
        </Card>
      </div>
      <br/><br/>
    </>
  )
}

const StockInput = ({stock, setStock, productData}) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]
    }
    const item = {
      product: prefillProduct || productData[0]._id,
      variant: prefillVariant?._id || productData[0]['variants'][0]._id,
      sku: prefillVariant?.sku || productData[0]['variants'][0].sku,
      stockOnHand: 0,
      manufacturingLeadTime: 0,
      currentFOB: 0
    };
    setStock(prev => [...prev, item])
  };

  const editVariantRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]

      newList[id]['variant'] = val
      newList[id]['stockOnHand'] = 0
      newList[id]['manufacturingLeadTime'] = 0
      newList[id]['currentFOB'] = 0
      newList[id]['sku'] = productData.find(val => newList[id]['product'] === val._id)['variants'].find(vari => vari._id === val)?.sku
      
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      let parsedInput = parseInt(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }
      newList[id]['stockOnHand'] = parsedInput
      return newList
    })
  };

  const editManufacturingLeadTimeRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      let parsedInput = parseInt(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }
      newList[id]['manufacturingLeadTime'] = parsedInput
      return newList
    })
  };

  const editCurrentFOBRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      let parsedInput = parseFloat(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }
      newList[id]['currentFOB'] = parsedInput
      return newList
    })
  };


  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((item, key) => key !== id)
      return newList
    })
  }; 

  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id)
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="4">
        Variant
      </Col>
      <Col xs="2">
        Manufacturing Lead Time
      </Col>
      <Col xs="2">
        Current FOB
      </Col>
      <Col xs="2">
        Stock On Hand
      </Col>
    </Row>
    {
      productData && stock.map((line, lineKey) => {
        console.log(line)

        return (
          <Row style={{padding: '12px 0'}}>
            <Col xs='4'>
              <ProductVariantSelector
                products={products}
                setSelectedVariant={(val) => {
                  editVariantRow(lineKey, val)
                }}
                selectedVariant={line.variant}
              />

            </Col>
            <Col xs="2">
              <InputGroup>
                <Input type="number" value={stock[lineKey]['manufacturingLeadTime']} onChange={(e) => editManufacturingLeadTimeRow(lineKey, e.target.value)}/>
                <InputGroupAddon addonType="append">days</InputGroupAddon>
              </InputGroup>
            </Col>
            <Col xs="2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input type="number" step="any" value={stock[lineKey]['currentFOB']} onChange={(e) => editCurrentFOBRow(lineKey, e.target.value)}/>
              </InputGroup>
            </Col>
            <Col xs="2">
              <Input type="number" disabled={true} value={stock[lineKey]['stockOnHand']} onChange={(e) => editQuantityRow(lineKey, e.target.value)}/>
            </Col>
            <Col xs="1" >
              <Button onClick={() => removeRow(lineKey)} color="danger">
                X
              </Button>{" "}
            </Col>
          </Row>
        )
      })
    }
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
      <Button onClick={handleAddRow} color="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
  </>
}

const FactoryPage = ({
  match: {
    params
  }
}) => {
  const { factoryID } = params

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [variantLines, setVariantLines] = useState([])

  const history = useHistory();
  const [loadingSave, setLoadingSave] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)

  const [factory, factoryState] = useQuery({
    url: `/factory/${factoryID}`
  });
  const [productData, productState] = useQuery({
    url: `/product`,
    refresh
  });

  useEffect(() => {
    if (factory) {
      setName(factory.name)
      setCode(factory.code)
      setVariantLines(factory.variantLines.map(line => ({
        ...line,
        variant: line.variant
      })))
    }
  }, [factory])


  const handleSave = (e) => {
    e.preventDefault()
    if (loadingSave === true) return 

    setLoadingSave(true)
    if (factoryID === 'create') {
      superagent.post(`${backendUrl}/factory/create`)
        .send({ 
          name: name, 
          code: code,
          variantLines: variantLines
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/factories/${response.body._id}`)
            setLoadingSave(false)
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.put(`${backendUrl}/factory/update/${factoryID}`)
        .send({ 
          name: name, 
          code: code,
          variantLines: variantLines
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            setLoadingSave(false)
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  const handleOnDrop = (data) => {
    let items = []
    data.map((line, key) => {
      if (key > 0) {
        const manufacturingLeadTime = line.data[1]
        const currentFOB = line.data[2]

        const sku = line.data[0]

        let product = productData.data.find(val => (val.variants.find(variant => variant.sku === sku) != undefined))
        if (product != undefined) {
          let variant = product.variants.find(variant => variant.sku === sku)
          if (variant != undefined) {
            const item = {
              variant: variant,
              currentFOB: currentFOB,
              manufacturingLeadTime: manufacturingLeadTime,
              sku: sku
            }; 
            items.push(item)
          }
        }
      }
    })

    setVariantLines(prev => {
      let newVariantLines = [...prev]
      items.map(variantLine => {
        const alreadyExists = newVariantLines.find(oldVariantLine => oldVariantLine.product === variantLine.product && oldVariantLine.variant === variantLine.variant)
        if (!alreadyExists) {
          newVariantLines.push(variantLine)
        } else {
          alreadyExists['currentFOB'] = variantLine['currentFOB']
          alreadyExists['manufacturingLeadTime'] = variantLine['manufacturingLeadTime']
          alreadyExists['sku'] = variantLine['sku']
        }
      })

      return newVariantLines
    })
  };
  const handleOnError = (err) => {
    console.log(err)
  }
  const handleOnRemoveFile = (file) => {
    console.log(file)
  }

  const csvData = [
    ['SKU', 'Stock On Hand', 'Current FOB', 'Manufacturing Lead Time'],
    ...variantLines.map(variantLine => [
      variantLine.variant?.sku, 
      variantLine['stockOnHand'], 
      variantLine['currentFOB'],
      variantLine['manufacturingLeadTime']
    ])
  ]


  const [stockLevels, stockLevelsState] = useQuery({
    url: `/purchase-order/factory/${factoryID}`
  });
  


  return (
    <>
      <SupplyChainNav
          commentComponent={<CommentHostory title="Comments" initCommentType={'Factory'} initOrderName={name} />}
      >
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    factoryState !== 'loading' ? (
                      <Form className="mt-4">
                        <Row>
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="locationname">Name</Label>
                              <Input id="locationname" name="locationname" type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="locationname">Code</Label>
                              <Input id="locationname" name="locationname" type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} />
                            </FormGroup>
                          </Col>
                          <Col sm="12" className="mt-4">
                            <Accordian close={loadingSave} title="Stock Produced" >
                              <Row>
                                <Col sm="11" />
                                <Col sm="1" style={{paddingBottom: 8}}>
                                  <CSVLink 
                                    data={csvData}
                                    filename={`${name}_stock_count.csv`}
                                  >
                                    <Button type="button" color="secondary" className="btn-lg btn-rounded">
                                    CSV
                                    </Button>
                                  </CSVLink>
                                </Col>
                              </Row>
                              <StockInput stock={variantLines} setStock={setVariantLines} productData={productData?.data} />
                              <div className="my-4">
                                <CSVReader
                                  onDrop={handleOnDrop}
                                  onError={handleOnError}
                                  onRemoveFile={handleOnRemoveFile}
                                >
                                  <span>Drop CSV file here or click to upload.</span>
                                </CSVReader>
                                <div className="mt-2 d-flex justify-content-center">
                                  <a href="https://docs.google.com/spreadsheets/d/1IKRTd60gREBhUgwmo29U81Scbb_fRLmqpKqO_NPNW1w/copy"> CSV Template </a>
                                </div>
                              </div>
                            </Accordian>
                          </Col>
                        </Row>
                        <Button onClick={handleSave} type="submit" color="success" className="mr-1 waves-effect waves-light">{loadingSave ? 'Saving...' :'Save Changes'}</Button>
                        <Row style={{marginTop: 16}}>
                          <Table className="table mb-0 mt-8">
                            <thead style={{position: 'sticky', top: 0, background: 'white', }}>
                                <tr>
                                    <th>SKU</th>
                                    <th>On Order</th>
                                    <th>On Hand</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!!stockLevels && Object.keys(stockLevels).map(factoryId => (
          stockLevels[factoryId].map(item => (
            <tr key={`${factoryId}-${item.sku}`}>
              <td>{item.sku}</td>
              <td>{item.lineQuantity - item.releaseQuantity}</td>
              <td>{item.releaseQuantity - item.containerQuantity}</td>
            </tr>
          ))
        ))}
                            </tbody>
                          </Table>

                        </Row>
                      </Form>
                    ) : (
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </SupplyChainNav>
    </>
  );
}

export default FactoryPage;
