import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import {
  CardTitle,
  Container,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import '../datatables.scss';
import CommentHistory from '../../Skote/Dashboard/CommentHistoryPending';
import useProductData from './useProductData';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { Country, State } from 'country-state-city';
import { useHistory } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import AsyncSelect from 'react-select/async';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';
import { embosColours, embosFonts } from './index.js';
import SKUToolTip from '../helpers/SKUToolTip.js';
import useSKULevels from '../helpers/useSKULevels.js';
import useSKUStatus from '../helpers/useSKUStatus.js';

import useUpcomingStock from '../helpers/useUpcomingStock.js';
import moment from 'moment';
import { error } from 'toastr';
import { toast } from 'react-toastify';

const OrderPage = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [modalEdit, setModalEdit] = useState();
  const [shippingModalEdit, setShippingModalEdit] = useState();
  const [addModalEdit, setAddModalEdit] = useState();
  const [editLineItemId, setEditLineItemId] = useState();
  const [comments, setComments] = useState([]);
  const [bulkOrder, setBulkOrder] = useState([]);
  const history = useHistory();

  const findSKULevel = useSKULevels();
  const findUpcomingStock = useUpcomingStock();
  const findSKUStatus = useSKUStatus();

  const [orderData, setOrderData] = useState({
    line_items: [],
    email: '',
    name: '',
    order_source: '',
    shipping_type: 'standard',
    skus: '',
    priority: '',
    deadline: '',
    shipping_address: {
      name: '',
      address1: '',
      phone: '',
      city: '',
      zip: '',
      province: 'Alabama',
      country: 'United States',
    },
  });

  const order = orderData;

  
  useEffect(() => {
    if (order?.name) {
      document.title = order?.name + ' | JIFI';
    }
  }, [order?.name]);

  const handleDeleteLineItem = (id) => {
    setOrderData((prev) => {
      let newData = { ...prev };
      newData.line_items = newData.line_items.filter(
        (lineItem, key) => key !== id
      );
      return newData;
    });
  };

  const handleEditOrder = (editData) => {
    setOrderData((prev) => {
      let newData = { ...prev };
      const keys = Object.keys(editData);
      for (let orderKey of keys) {
        newData[orderKey] = editData[orderKey];
      }
      return newData;
    });
  };

  const getVariant = useProductData();

  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  const handleEditLineItem = (id) => {
    setEditLineItemId(id);
    togglemodalEdit();
  };

  const togglemodalEdit = () => {
    setModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleAddModalEdit = () => {
    setAddModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleShippingNodalEdit = () => {
    setShippingModalEdit((prev) => !prev);
    // printLabels()
  };

  const handleEditShipping = (e) => {
    e.preventDefault();
    toggleShippingNodalEdit();
  };


  const createBulkOrder = () => {
    setLoading(true);

    superagent
      .post(`${backendUrl}/orders/createMultipleOrder`)
      .send(bulkOrder)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          toast.success('Orders created successfully');
          setLoading(false);
          setBulkOrder([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const createOrder = () => {
    const sendData = {
      order: order,
      comments: comments,
    };

    setLoading(true);

    superagent
      .post(`${backendUrl}/orders/createCustom`)
      .send(sendData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          setLoading(false);
          setOrderData({
            line_items: [],
            email: '',
            name: '',
            order_source: '',
            shipping_type: 'standard',
            skus: '',
            priority: '',
            deadline: '',
            shipping_address: {
              name: '',
              address1: '',
              phone: '',
              city: '',
              zip: '',
              province: 'Alabama',
              country: 'United States',
            },
          });
          window.open(`/orders/${response.body._id}`, '_blank');
          history.go(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const orderIsReady =
    order.name != '' &&
    order?.order_source != '' &&
    order?.line_items?.length > 0;

  const validateMonogramData = (sku, color, font, location) => {
    const isPersonalized = isPersonalizedSKU(sku);
    const isBagProduct =
      ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK'].includes(sku.slice(0, 3)) ||
      ['EVETOT'].includes(sku.slice(0, 6));
    const colorOptions = isBagProduct ? newColorsBags : newColors;
    const fontOptions = isBagProduct ? fontsBags : fonts;

    const isValidColor = color && Object.keys(colorOptions).includes(color);
    const isValidFont = font && Object.keys(fontOptions).includes(font);
    let locationOptions;
    if (sku.includes('NBK')) {
      locationOptions = Notebooklocations;
    } else if (sku.includes('TEC') || sku.includes('CME')) {
      locationOptions = TecLocations;
    } else if (sku.includes('TAG')) {
      locationOptions = TagLocations;
    } else {
      locationOptions = locations;
    }
    const isValidLocation =
      location && Object.values(locationOptions).includes(location);

    return isPersonalized
      ? isValidColor && isValidFont && isValidLocation
      : true;
  };

  async function validateAddress({ address1 }) {
    const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`;

    const payload = {
      address: {
        addressLines: [address1],
      },
    };

    for (let key in payload.address) {
      if (payload.address[key] === undefined) delete payload.address[key];
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data.result) {
        const validationResult = data.result;

        if (validationResult.verdict.addressComplete) {
            return {
              valid: true,
              formattedAddress: validationResult.address.formattedAddress,
              geocode: validationResult.geocode.location,
              components: validationResult.address.addressComponents,
            };
          
        } else {
          console.log('Address is incomplete or inaccurate.');
          return {
            valid: false,
            message: 'Address is incomplete or inaccurate.',
          };
        }
      } else {
        console.error('Validation error:', data.error.message);
        return { valid: false, message: data.error.message };
      }
    } catch (error) {
      console.error('Error validating address:', error.message);
      return { valid: false, message: error.message };
    }
  }

  const loadProductList = async () => {
    try {
      const response = await superagent
        .get(`${backendUrl}/product`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json');

      const code = response.status;
      if (code <= 400 && response.body && response.body.data) {
        return response.body.data;
      } else {
        console.log('Failed to load products.');
        return [];
      }
    } catch (err) {
      console.error('Error loading products:', err);
      return [];
    }
  };

  const checkSKU = (sku, productList) => {
    for (let product of productList) {
      for (let variant of product.variants) {
        const baseSku = variant.sku;
        const personalizedSku = `${baseSku}-P`;

        const isAllowed =
          [
            'COP',
            'CHK',
            'PLS',
            'CME',
            'BKP',
            'BPK',
            'WKD',
            'TOT',
            'PRO',
            'LGT',
            'BOT',
            'IPC',
            'TRK',
            'NBK',
            'TAG',
            'SET',
            'TEC',
            'COE',
            'EXP',
            'EVE',
            'DBK',
            'SLG',
            'JET',
            'COM',
          ].includes(baseSku.slice(0, 3)) ||
          ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(
            baseSku.slice(0, 7)
          ) ||
          ['CAR-WKDP'].includes(baseSku.slice(0, 8));

        if (variant.sku === sku || (isAllowed && `${variant.sku}-P` === sku)) {
          return true;
        }
      }
    }
    return false;
  };

  const isPersonalizedSKU = (sku) => {
    const baseSku = sku
      .split('-')
      .filter((val) => val !== 'P' && val !== 'LE')
      .join('-');
    return baseSku !== sku;
  };

  const handleOnDrop = (data) => {
    try {
      const lines = [];
      console.log(data);
      data.map((line, key) => {
        if (key > 0 && line.data.length > 1) {
          console.log(line.data);
          let lineItem = {
            sku: line.data[0],
            quantity: line.data[1],
            ...(line.data[0]
              .split('-')
              .filter((val) => val !== 'P' && val !== 'LE')
              .join('-') !== line.data[0] && line.data.length > 2
              ? {
                  monogram_text: decodeURIComponent(
                    encodeURIComponent(line.data[2])
                  ),
                  monogram_font: line.data[6].toLowerCase(),
                  monogram_color: line.data[4],
                  monogram_position: line.data[3],
                  ...(line.data[6].toLowerCase() === 'block' ||
                  line.data[6].toLowerCase() === 'college'
                    ? { monogram_shadow: line.data[5].toLowerCase() }
                    : {}),
                  monogram: true,
                }
              : {
                  monogram: false,
                }),
          };
          lines.push(lineItem);
        }
      });
      handleEditOrder({ line_items: [...order.line_items, ...lines] });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnDropBulkUpload = async (data) => {
    try {
        const products = await loadProductList();
        const errors = [];

        const allowedPriorities = ["high", "medium", "low"];
        const allowedShippingTypes = ["standard", "express", "pickup", "pickup_hq", "Uber", "overnight"];


        const orders = await Promise.all(
            data.map(async (line, key) => {
                if (key === 0 || line.data.length <= 1) return null;

                const [
                    jifiOrderNo,
                    sku,
                    monoText,
                    monoLocation,
                    monoColor,
                    monoShadow,
                    monoFont,
                    quantity,
                    dueDate,
                    priority,
                    firstName,
                    lastName,
                    mobile,
                    email,
                    company,
                    deliveryAddress,
                    suburb,
                    city,
                    country,
                    postcode,
                    address2,
                    shippingType
                  
                ] = line.data;

                const requiredAddressFields = {
                    deliveryAddress: deliveryAddress?.trim(),
                    suburb: suburb?.trim(),
                    city: city?.trim(),
                    country: country?.trim(),
                    postcode: postcode?.trim(),
                };

             
                const addressString = `${requiredAddressFields.deliveryAddress}, ${requiredAddressFields.suburb}, ${requiredAddressFields.city}, ${requiredAddressFields.country}, ${requiredAddressFields.postcode}`;
               

                if (!jifiOrderNo?.trim()) {
                    errors.push(`Row ${key + 1}: "Jifi Order Number" cannot be blank.`);
                    return null;
                }

                if (!checkSKU(sku, products)) {
                    errors.push(`Row ${key + 1}, Order ${jifiOrderNo}: SKU "${sku}" does not exist in the product list.`);
                    return null;
                }

                const isMonogramValid = validateMonogramData(sku, monoColor, monoFont, monoLocation);
                if (!isMonogramValid) {
                    errors.push(`Row ${key + 1}, SKU ${sku}: Invalid monogram details (color: ${monoColor}, font: ${monoFont}, location: ${monoLocation}).`);
                    return null;
                }

              if (priority && !allowedPriorities.includes(priority.toLowerCase())) {
                  errors.push(`Row ${key + 1}, Order ${jifiOrderNo}: Priority "${priority}" is invalid. Allowed values are: ${allowedPriorities.join(", ")}.`);
                  return null;
              }

              
              if (shippingType && !allowedShippingTypes.includes(shippingType.toLowerCase())) {
                  errors.push(`Row ${key + 1}, Order ${jifiOrderNo}: Shipping type "${shippingType}" is invalid. Allowed values are: ${allowedShippingTypes.join(", ")}.`);
                  return null;
              }

                const validAddress = await validateAddress({ address1: addressString });
                if (!validAddress.valid) {
                    errors.push(`Row ${key + 1}, Order ${jifiOrderNo}: Address is invalid. Please check both "Address" and "Additional Address" fields.`);
                    return null;
                }

                const lineItem =
                    sku.split('-').filter((val) => val !== 'P' && val !== 'LE').join('-') !== sku && line.data.length > 2
                        ? {
                              sku,
                              quantity: quantity ?? 1,
                              monogram_text: monoText,
                              monogram_font: monoFont,
                              monogram_color: monoColor,
                              monogram_position: monoLocation,
                              ...(monoFont === 'block' || monoFont === 'college' || monoFont === 'heritage' || monoFont === 'decorative' ? { monogram_shadow: monoShadow } : {}),
                              monogram: true,
                          }
                        : {
                              sku,
                              quantity: quantity ?? 1,
                              monogram: false,
                          };

                const shippingAddress = {
                    name: `${firstName} ${lastName}`.trim(),
                    address1: deliveryAddress,
                    address2: address2,
                    company,
                    phone: mobile,
                    city,
                    zip: postcode,
                    province: suburb,
                    country: country,
                };

                return {
                    name: jifiOrderNo,
                    email,
                    order_source: 'corp-individual',
                    shipping_type: shippingType ?? 'standard',
                    skus: '',
                    priority,
                    deadline: moment(dueDate, 'D/M/YYYY').toDate(),
                    shipping_address: shippingAddress,
                    line_items: [lineItem],
                };
            })
        );

        const validOrders = orders.filter(Boolean);
        if (errors.length > 0) {
            toast.warning(
                <div>
                    <strong>Error Summary:</strong>
                    {errors.map((error, index) => (
                        <div key={index}>{error}</div>
                    ))}
                </div>
            );
        } else {
          setBulkOrder(validOrders);
        }
        
    } catch (e) {
        console.log(e);
    }
};





  
  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log(data);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col s="12" md="8">
            <h2 className="mb-4"> Create Order</h2>
            <Row>
              <Col sm="12" className="mt-4"></Col>

              <Col xs="12">
                <Card>
                  <CardBody>
                    <Input
                      placeholder="Order Name"
                      className="mb-2"
                      type="text"
                      value={order.name}
                      onChange={(e) => {
                        handleEditOrder({ name: e.target.value });
                      }}
                    />
                    <Input
                      onChange={(e) => {
                        handleEditOrder({ order_source: e.target.value });
                      }}
                      value={order.order_source}
                      type="select"
                      name="select"
                      id="exampleSelect"
                    >
                      <option default={true} value={``}>
                        {' '}
                        --PLEASE SELECT SOURCE--{' '}
                      </option>
                      <option value={`goop`}> Goop </option>
                      <option value={`custom`}> Custom </option>
                      <option value={`corp`}> Corporate </option>
                      <option value={`corp-individual`}>
                        Corporate - BULK UPLOAD
                      </option>
                    </Input>
                    <br />
                    {loading ? (
                      <></>
                    ) : (
                      <Button
                        onClick={createOrder}
                        color="success"
                        disabled={
                          !orderIsReady ||
                          order.order_source === 'corp-individual'
                        }
                      >
                        {' '}
                        Create{' '}
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {loading === false ? (
              <Row>
                <Col xs="12">
                  {orderData.order_source === 'corp-individual' && (
                    <Card close={false}>
                      <CardBody>
                        <CardTitle> Bulk Upload</CardTitle>

                        <div className="my-4">
                          <CSVReader
                            onDrop={handleOnDropBulkUpload}
                            onError={handleOnError}
                            addRemoveButton
                            onRemoveFile={handleOnRemoveFile}
                          >
                            <span>Drop CSV files here create orders.</span>
                          </CSVReader>
                          <div className="mt-2 d-flex justify-content-center">
                            <a 
                             target="_blank"
                            href="https://docs.google.com/spreadsheets/d/1KoYlmhrjvVwmJx-DTTCYHfKx0ekeVAyELrkSL6Wrv9E/copy" rel="noreferrer">
                              {' '}
                              CSV Template{' '}
                            </a>
                          </div>
                          <Button
                            disabled={loading || bulkOrder.length === 0}
                            onClick={createBulkOrder}
                            color="primary"
                            style={{ marginTop: 32 }}
                          >
                            {' '}
                            Bulk Upload{' '}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  <Card>
                    <CardBody>
                      <CardTitle>Line Items</CardTitle>
                      <div>
                        <Row>
                          <Col sm="12">
                            {order?.line_items &&
                              order.line_items.map(
                                (lineItem, lineItemIndex) => {
                                  return (
                                    <Row>
                                      <Col sm="2">
                                        <div
                                          style={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <img
                                            src={
                                              getVariant(lineItem.sku)?.image
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4" className="p-3">
                                        <h6>
                                          {getVariant(lineItem.sku)?.name}
                                        </h6>
                                        <div>
                                          SKU:{' '}
                                          <SKUToolTip
                                            sku={lineItem.sku}
                                            findSKULevel={findSKULevel}
                                            findUpcomingStock={
                                              findUpcomingStock
                                            }
                                            findSKUStatus={findSKUStatus}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.monogram && (
                                          <a
                                            href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${lineItem.monogram_font.toLowerCase()}-${
                                              lineItem.monogram_text
                                            }-${lineItem.monogram_color}-${
                                              lineItem.monogram_shadow || ''
                                            }-undefined${
                                              lineItem.sku.startsWith('IPC')
                                                ? `-IPC-${lineItem.monogram_position}`
                                                : ''
                                            }${
                                              lineItem.sku.startsWith('BOT') &&
                                              lineItem.monogram_position ===
                                                'Top'
                                                ? '-Lid'
                                                : ''
                                            }.pdf`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <div
                                              style={{
                                                opactity: 0.8,
                                                fontSize: 10,
                                              }}
                                            >
                                              <div>
                                                TEXT: {lineItem.monogram_text}
                                              </div>
                                              <div>
                                                FONT: {lineItem.monogram_font}
                                              </div>
                                              <div>
                                                COLOUR:{' '}
                                                {lineItem.monogram_color}
                                              </div>
                                              {(lineItem.monogram_font ===
                                                'block' ||
                                                lineItem.monogram_font ===
                                                  'college') && (
                                                <div>
                                                  SHADOW:{' '}
                                                  {lineItem.monogram_shadow}
                                                </div>
                                              )}
                                              <div>
                                                POSITION:{' '}
                                                {lineItem.monogram_position}
                                              </div>
                                            </div>
                                          </a>
                                        )}
                                        {lineItem.message && (
                                          <div
                                            style={{
                                              opactity: 0.8,
                                              fontSize: 10,
                                            }}
                                          >
                                            <div>
                                              message: {lineItem.message}
                                            </div>
                                          </div>
                                        )}
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.quantity}x ${lineItem.price}
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#"
                                            className="card-drop"
                                            tag="i"
                                          >
                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                          </DropdownToggle>
                                          <DropdownMenu right>
                                            <DropdownItem
                                              onClick={() =>
                                                handleEditLineItem(
                                                  lineItemIndex
                                                )
                                              }
                                              href="#"
                                            >
                                              {' '}
                                              Edit{' '}
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                handleDeleteLineItem(
                                                  lineItemIndex
                                                )
                                              }
                                              href="#"
                                            >
                                              {' '}
                                              Delete{' '}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                          </Col>
                          <Button
                            onClick={toggleAddModalEdit}
                            color="primary"
                            className="ml-2"
                            style={{ marginTop: 32 }}
                          >
                            {' '}
                            +Add Line Item{' '}
                          </Button>
                        </Row>
                      </div>
                      <br />
                      <div>
                        <a
                          style={{ float: 'right' }}
                          target="_blank"
                          href="https://docs.google.com/spreadsheets/d/1u9dD35APlll10Tw0Id-4gWtm-wCvluZ8lweUZ9oLQCg/copy"
                          rel="noreferrer"
                        >
                          {' '}
                          CSV Template{' '}
                        </a>
                      </div>
                      <br />
                      <div>
                        <CSVReader
                          onDrop={handleOnDrop}
                          onError={handleOnError}
                          onRemoveFile={handleOnRemoveFile}
                        >
                          <span>Drop CSV file here or click to upload.</span>
                        </CSVReader>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Col>
          <Col s="12" md="4">
            <div style={{ position: 'relative', height: '100%' }}>
              <div id="stickyTest" style={{ position: 'sticky', top: 150 }}>
                <CommentHistory
                  comments={comments}
                  setComments={setComments}
                  title="Notes"
                  initCommentType={'Order'}
                  initOrderName={order?.name}
                />
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Customer Info</h4>
                    <h6 style={{ marginBottom: 16 }}>
                      {' '}
                      {`${order?.shipping_address?.name}`}{' '}
                    </h6>
                    <div>
                      {' '}
                      <span style={{ opacity: 0.5 }}>EMAIL:</span>{' '}
                      {order?.email}{' '}
                    </div>
                    <div>
                      {' '}
                      <span style={{ opacity: 0.5 }}>SHIPPING TYPE:</span>{' '}
                      {order?.shipping_type}{' '}
                    </div>
                    <br />
                    <h6 style={{ marginBottom: 16 }}>
                      {' '}
                      Shipping Address -{' '}
                      <a href="#" onClick={handleEditShipping}>
                        edit
                      </a>
                    </h6>
                    <div> {order?.shipping_address?.name} </div>
                    <div> {order?.shipping_address?.address1} </div>
                    <div> {order?.shipping_address?.address2 || ''} </div>
                    <div>
                      {' '}
                      {`${order?.shipping_address?.city || ''} ${
                        order?.shipping_address?.province || ''
                      } ${order?.shipping_address?.zip || ''}`}{' '}
                    </div>
                    <div> {order?.shipping_address?.country || ''} </div>
                    <div> {order?.shipping_address?.phone || ''} </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={shippingModalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleShippingNodalEdit}
      >
        <EditModalShipping
          handleEditOrder={handleEditOrder}
          order={order}
          refresh={refreshData}
          togglemodal={toggleShippingNodalEdit}
        />
      </Modal>

      <Modal
        isOpen={modalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={togglemodalEdit}
      >
        <EditModal
          order={order}
          handleEditOrder={handleEditOrder}
          editLineItemId={editLineItemId}
          refresh={refreshData}
          togglemodal={togglemodalEdit}
        />
      </Modal>

      <Modal
        isOpen={addModalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleAddModalEdit}
      >
        {addModalEdit === true && (
          <AddModal
            handleEditOrder={handleEditOrder}
            order={order}
            editLineItemId={editLineItemId}
            refresh={refreshData}
            togglemodal={toggleAddModalEdit}
          />
        )}
      </Modal>
    </div>
  );
};

const EditModal = ({ togglemodal, editLineItemId, handleEditOrder, order }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [quantity, setQuantity] = useState();
  const editLineItem = order.line_items[editLineItemId];
  useEffect(() => {
    setLoading(true);
    superagent
      .get(
        `${backendUrl}/product/variant/${editLineItem.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-')}/find`
      )
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body);
          setSelectedVariant(editLineItem);
          setQuantity(editLineItem.quantity);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    if (editLineItem.monogram === true) {
      setMonogramText(editLineItem.monogram_text);
      setMonogramFont(editLineItem.monogram_font);
      setMonogramColor(editLineItem.monogram_color);
      setMonogramShadow(editLineItem.monogram_shadow || 'None');
      setMonogramLocation(editLineItem.monogram_position);
    }
  }, [editLineItem]);

  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const updateLineItem = () => {
    let newLineItem = {
      _id: editLineItem._id,
      sku: selectedVariant?.sku,
      quantity: quantity,
      ...(selectedVariant?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedVariant?.sku
        ? {
            monogram_text: encodeURIComponent(monogramText),
            monogram_font: monogramFont,
            monogram_color: monogramColor,
            monogram_position: monogramLocation,
            ...(monogramFont === 'block' ||
            monogramFont === 'college' ||
            monogramFont === 'heritage' ||
            monogramFont === 'decorative'
              ? { monogram_shadow: monogramShadow }
              : {}),
            monogram: true,
          }
        : {
            monogram: false,
          }),
    };
    let lineItems = order.line_items;
    lineItems[editLineItemId] = newLineItem;
    handleEditOrder({ line_items: lineItems });
    togglemodal();
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Line Item</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  const colorType = selectedVariant
    ? ['BKP', 'WKD', 'TOT', 'CAR'].includes(selectedVariant?.sku.slice(0, 3))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;

  const isEmboss = selectedVariant && selectedVariant?.sku.includes('JETTE');
  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Edit Line Item</ModalHeader>
        <ModalBody>
          <ProductVariantSelector
            products={options}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
          <br />
          <Input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <br />
          {selectedVariant &&
            selectedVariant?.sku
              ?.split('-')
              .filter((val) => val !== 'P' && val !== 'LE')
              .join('-') !== selectedVariant?.sku && (
              <div>
                <br />
                <h6> Monogram Data</h6>
                <br />
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    {' '}
                    Text{' '}
                  </label>
                  <div className="col-md-10">
                    <Input
                      placeholder="Monogram text"
                      className="mb-2"
                      type="text"
                      value={monogramText}
                      onChange={(e) => setMonogramText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    {' '}
                    Font{' '}
                  </label>
                  <div className="col-md-10">
                    <Input
                      className="mb-2"
                      onChange={(e) => setMonogramFont(e.target.value)}
                      value={monogramFont}
                      type="select"
                      name="select"
                    >
                      <option disabled selected value>
                        {' '}
                        -- select an option --{' '}
                      </option>
                      {Object.keys(fontsFor).map((val) => (
                        <option value={val}>{fontsFor[val]}</option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Main Colour
                  </label>
                  <div className="col-md-10">
                    <Input
                      className="mb-2"
                      onChange={(e) => setMonogramColor(e.target.value)}
                      value={monogramColor}
                      type="select"
                      name="select"
                    >
                      <option disabled selected value>
                        {' '}
                        -- select an option --{' '}
                      </option>
                      {Object.keys(colors).map((val) => (
                        <option style={{ color: colors[val] }} value={val}>
                          {val}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                {(monogramFont === 'block' ||
                  monogramFont === 'college' ||
                  monogramFont === 'heritage' ||
                  monogramFont === 'decorative') && (
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Shadow Colour
                    </label>
                    <div className="col-md-10">
                      <Input
                        className="mb-2"
                        onChange={(e) => setMonogramShadow(e.target.value)}
                        value={monogramShadow}
                        type="select"
                        name="select"
                      >
                        <option disabled selected value>
                          {' '}
                          -- select an option --{' '}
                        </option>
                        {Object.keys(newColors).map((val) => (
                          <option style={{ color: newColors[val] }} value={val}>
                            {val}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Location
                  </label>
                  <div className="col-md-10">
                    <Input
                      className="mb-2"
                      onChange={(e) => setMonogramLocation(e.target.value)}
                      value={monogramLocation}
                      type="select"
                      name="select"
                    >
                      <option disabled selected value>
                        {' '}
                        -- select an option --{' '}
                      </option>
                      {editLineItem.sku.includes('NBK')
                        ? Object.keys(Notebooklocations).map((val) => (
                            <option value={Notebooklocations[val]}>
                              {Notebooklocations[val]}
                            </option>
                          ))
                        : editLineItem.sku.includes('TEC') ||
                          editLineItem.sku.includes('CME')
                        ? Object.keys(TecLocations).map((val) => (
                            <option value={TecLocations[val]}>
                              {TecLocations[val]}
                            </option>
                          ))
                        : editLineItem.sku.includes('TAG')
                        ? Object.keys(TagLocations).map((val) => (
                            <option value={TagLocations[val]}>
                              {TagLocations[val]}
                            </option>
                          ))
                        : Object.keys(locations).map((val) => (
                            <option value={locations[val]}>
                              {locations[val]}
                            </option>
                          ))}
                    </Input>
                  </div>
                </div>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={updateLineItem}>
            {' '}
            Save{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const EditModalShipping = ({ togglemodal, order, handleEditOrder }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ['address_component', 'types', 'geocode'],
      types: ['geocode'],
    },
  });

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', {
      detail: placePredictions.map((placeLine) => ({
        label: placeLine.description,
        value: placeLine,
      })),
    });
    document.dispatchEvent(loaded);
  }, [placePredictions]);

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(
        document.getElementById('test-map'),
        { zoom: 15 }
      );
      var request = {
        placeId: placeID,
        fields: ['address_components'],
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej(place);
        }
      }
    });
  };

  const loadOptions = (inputValue, callback) => {
    getPlacePredictions({ input: inputValue });
    document.addEventListener('loadedPlaced', function (e) {
      callback(e.detail);
    });
  };
  const [loading, setLoading] = useState(false);

  const [shippingType, setShippingType] = useState(order.shipping_type);
  const handleShippingTypeChange = (e) => {
    setShippingType(e.target.value);
  };

  const [fastFill, setFastFill] = useState('');
  useEffect(() => {
    if (fastFill !== '') {
      let split = fastFill.split('	');
      setName(split[0]);
      setEmail(split[1]);
      setPhone(split[2]);
      setAddress1(split[3]);
      setAddress2(split[4]);
      setCity(split[5]);
      setState(split[6]);
      setZip(split[7]);
      setCountry(split[8]);
      setFastFill('');
    }
  }, [fastFill]);

  // 0: "Lisa Polon"
  // 1: "lisapolon88@yahoo.com"
  // 2: "3108942862"
  // 3: "4809 Laurelgrove Ave"
  // 4: "103"
  // 5: "Valley Village"
  // 6: "CA"
  // 7: "91607"

  const [name, setName] = useState(order.shipping_address.name);
  const [email, setEmail] = useState(order.email);
  const [company, setCompany] = useState(order.shipping_address.company);
  const [address1, setAddress1] = useState(order.shipping_address.address1);
  const [address2, setAddress2] = useState(order.shipping_address.address2);
  const [city, setCity] = useState(order.shipping_address.city);
  const [zip, setZip] = useState(order.shipping_address.zip);
  const [phone, setPhone] = useState(order.shipping_address.phone);
  const [deadline, setDeadline] = useState(order.deadline);
  const [priority, setPriority] = useState(order.priority);

  const [selectedState, setState] = useState(
    State.getStatesOfCountry(
      Country.getAllCountries().find(
        (val) => val.name === order.shipping_address.country
      )?.isoCode
    )?.find((val) => val.name === order.shipping_address.province)?.isoCode
  );
  const [selectedCountry, setCountry] = useState(
    Country.getAllCountries().find(
      (val) => val.name === order.shipping_address.country
    )?.isoCode
  );

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let newState;
    try {
      newState = State.getStatesOfCountry(e.target.value)[0].isoCode;
    } catch {}
    setState(newState);
  };

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then((res) => {
        let items = res.address_components;
        let streetNumberGoogleAPI,
          streetAddressGoogleAPI,
          countryGoogleAPI,
          stateGoogleAPI,
          unitNoGoogleAPI,
          suburnGoogleAPI,
          postcodeGoogleAPI;

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name;
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name;
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name;
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name;
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name;
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name;
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name;
          }
        });

        setCountry(
          Country.getAllCountries().find((val) => val.name === countryGoogleAPI)
            ?.isoCode
        );
        setState(
          State.getStatesOfCountry(
            Country.getAllCountries().find(
              (val) => val.name === countryGoogleAPI
            )?.isoCode
          )?.find((val) => val.name === stateGoogleAPI)?.isoCode
        );
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI);
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI);
        setAddress2('');
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(
                unitNoGoogleAPI +
                  '/' +
                  streetNumberGoogleAPI +
                  ' ' +
                  streetAddressGoogleAPI
              );
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
            }
          } else {
            setAddress1(streetAddressGoogleAPI);
          }
        } else {
          setAddress1('');
        }
        setSelected('');
      });
    }
  }, [selected]);

  const handleSave = () => {
    const updatedShipping = {
      shipping_type: shippingType,
      email: email,
      deadline: deadline,
      priority: priority,
      shipping_address: {
        name: name,
        address1: address1,
        address2: address2,
        company: company,
        phone: phone,
        city: city,
        zip: zip,
        province: State.getStateByCodeAndCountry(selectedState, selectedCountry)
          .name,
        country: Country.getCountryByCode(selectedCountry).name,
      },
    };
    console.log(updatedShipping);
    handleEditOrder(updatedShipping);
    togglemodal();
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Shipping Details</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div id="test-map" />
      </div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Edit Shipping Details</ModalHeader>
        <ModalBody>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Shipping Type
            </label>
            <div className="col-md-10">
              <Input
                onChange={handleShippingTypeChange}
                type="select"
                name="select"
                id="exampleSelect"
              >
                <option selected={shippingType === 'standard'} value="standard">
                  {' '}
                  Standard{' '}
                </option>
                <option selected={shippingType === 'express'} value="express">
                  {' '}
                  Express{' '}
                </option>
                <option selected={shippingType === 'pickup'} value="pickup">
                  {' '}
                  QV Pickup{' '}
                </option>
                <option
                  selected={shippingType === 'pickup_hq'}
                  value="pickup_hq"
                >
                  {' '}
                  HQ Pickup{' '}
                </option>
                <option selected={shippingType === 'Uber'} value="Uber">
                  {' '}
                  Uber{' '}
                </option>
                <option
                  selected={shippingType === 'Overnight'}
                  value="overnight"
                >
                  {' '}
                  Overnight{' '}
                </option>
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Name
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                type="text"
                value={name}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Email
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                type="text"
                value={email}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Phone
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                type="text"
                value={phone}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Company
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setCompany(e.target.value)}
                className="form-control"
                type="text"
                value={company}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Search Address
            </label>
            <div className="col-md-10">
              <AsyncSelect
                onClick={setSelected}
                onChange={setSelected}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Country
            </label>
            <div className="col-md-10">
              <Input
                onChange={(e) => setCountry(e.target.value)}
                type="select"
                name="select"
                id="exampleSelect"
              >
                {Country.getAllCountries().map((country) => (
                  <option
                    value={country.isoCode}
                    selected={selectedCountry === country.isoCode}
                  >
                    {' '}
                    {country.name}{' '}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              State
            </label>
            <div className="col-md-10">
              <Input
                onChange={(e) => setState(e.target.value)}
                type="select"
                name="select"
                id="exampleSelect"
              >
                {State.getStatesOfCountry(selectedCountry).map((state) => (
                  <option
                    value={state.isoCode}
                    selected={selectedState === state.isoCode}
                  >
                    {' '}
                    {state.name}{' '}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Address
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setAddress1(e.target.value)}
                className="form-control"
                type="text"
                value={address1}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Suburb
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setCity(e.target.value)}
                className="form-control"
                type="text"
                value={city}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Postcode
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setZip(e.target.value)}
                className="form-control"
                type="text"
                value={zip}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Apartment, suite, etc. (optional)
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setAddress2(e.target.value)}
                className="form-control"
                type="text"
                value={address2}
              />
            </div>
          </div>
          {order.order_source === 'corp' && (
            <>
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                >
                  Deadline
                </label>
                <div className="col-md-10">
                  <input
                    onChange={(e) => setDeadline(e.target.value)}
                    className="form-control"
                    type="date"
                    value={deadline}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                >
                  Priority
                </label>
                <div className="col-md-10">
                  <Input
                    onChange={(e) => setPriority(e.target.value)}
                    value={priority}
                    type="select"
                    name="select"
                    id="exampleSelect"
                  >
                    <option disabled selected value>
                      {' '}
                      -- select an option --{' '}
                    </option>
                    {['High', 'Medium', 'Low'].map((option) => (
                      <option value={option}> {option} </option>
                    ))}
                  </Input>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSave}>
            {' '}
            Save{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const AddModal = ({ togglemodal, order, handleEditOrder }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    setLoading(true);
    superagent
      .get(`${backendUrl}/product`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body.data);
          setSelectedProduct(response.body.data[0]._id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const productObject =
    selectedProduct && options.find((val) => val._id === selectedProduct);
  const variantOption =
    productObject &&
    selectedVariant &&
    productObject.variants.find(
      (val) =>
        val.sku ===
        val.sku
          .split('-')
          .filter((val) => val !== 'P')
          .join('-')
    );

  const updateLineItem = () => {
    let newLineItem = {
      sku: selectedVariant?.sku,
      quantity: quantity,
      ...(selectedVariant?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedVariant?.sku
        ? {
            monogram_text: encodeURIComponent(monogramText),
            monogram_font: monogramFont,
            monogram_color: monogramColor,
            monogram_position: monogramLocation,
            ...(monogramFont === 'block' ||
            monogramFont === 'college' ||
            monogramFont === 'heritage' ||
            monogramFont === 'decorative'
              ? { monogram_shadow: monogramShadow }
              : {}),
            monogram: true,
          }
        : {
            monogram: false,
          }),
    };

    handleEditOrder({ line_items: [...order.line_items, newLineItem] });
    togglemodal();
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Line Item</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  const colorType = selectedVariant
    ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK'].includes(
        selectedVariant?.sku.slice(0, 3)
      ) || ['EVETOT'].includes(selectedVariant?.sku.slice(0, 6))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;
  const monogramTypes = {
    text: 'Text',
    pets: 'Pets',
  };

  const isEmboss = selectedVariant && selectedVariant?.sku.includes('JETTE');
  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Add Line Item</ModalHeader>
        <ModalBody>
          <ProductVariantSelector
            products={options}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
          <br />
          <Input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <br />
          {selectedVariant &&
            selectedVariant?.sku
              .split('-')
              .filter((val) => val !== 'P' && val !== 'LE')
              .join('-') !== selectedVariant?.sku && (
              <div>
                <h6> Monogram Data </h6>
                <Input
                  placeholder="Monogram text"
                  className="mb-2"
                  type="text"
                  value={monogramText}
                  onChange={(e) => setMonogramText(e.target.value)}
                />
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramFont(e.target.value)}
                  value={monogramFont}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {Object.keys(fontsFor).map((val) => (
                    <option value={val}>{fontsFor[val]}</option>
                  ))}
                </Input>
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramColor(e.target.value)}
                  value={monogramColor}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {Object.keys(colors).map((val) => (
                    <option style={{ color: colors[val] }} value={val}>
                      {val}
                    </option>
                  ))}
                </Input>
                {(monogramFont === 'block' ||
                  monogramFont === 'college' ||
                  monogramFont === 'heritage' ||
                  monogramFont === 'decorative') && (
                  <Input
                    className="mb-2"
                    onChange={(e) => setMonogramShadow(e.target.value)}
                    value={monogramShadow}
                    type="select"
                    name="select"
                  >
                    <option disabled selected value>
                      {' '}
                      -- select an option --{' '}
                    </option>
                    {Object.keys(newColors).map((val) => (
                      <option style={{ color: newColors[val] }} value={val}>
                        {val}
                      </option>
                    ))}
                  </Input>
                )}
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramLocation(e.target.value)}
                  value={monogramLocation}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {selectedVariant?.sku?.includes('NBK')
                    ? Object.keys(Notebooklocations).map((val) => (
                        <option value={Notebooklocations[val]}>
                          {Notebooklocations[val]}
                        </option>
                      ))
                    : selectedVariant?.sku?.includes('TEC') ||
                      selectedVariant?.sku?.includes('CME')
                    ? Object.keys(TecLocations).map((val) => (
                        <option value={TecLocations[val]}>
                          {TecLocations[val]}
                        </option>
                      ))
                    : selectedVariant?.sku?.includes('TAG')
                    ? Object.keys(TagLocations).map((val) => (
                        <option value={TagLocations[val]}>
                          {TagLocations[val]}
                        </option>
                      ))
                    : Object.keys(locations).map((val) => (
                        <option value={locations[val]}>{locations[val]}</option>
                      ))}
                </Input>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={updateLineItem}>
            {' '}
            Add{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const newColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#ffffff',
  Grey: '#97999D',
  Black: '#000',
};
export const newColors = {
  Lavender: '#90759B',
  Lilac: '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  Grenaine: '#9E5360',
  'Blush-Pink': '#E77472',
  Magenta: '#B12D52',
  Maroon: '#731A29',
  Red: '#C92327',
  'Burnt-Orange': '#DC5527',
  Mango: '#EF7E2E',
  Rust: '#9B4827',
  Toffee: '#705037',
  Taupe: '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  Yellow: '#E8B629',
  Lime: '#C6B937',
  Green: '#4A813C',
  'Forest-Green': '#034123',
  Mint: '#78AB93',
  'Ice-Blue': '#BEE3E9',
  Denim: '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  Blue: '#1466B2',
  Grey: '#95979E',
  'Slate-Grey': '#63727E',
  Black: '#000000',
  White: '#FFFFFF',
};
export const fonts = {
  heritage: 'Heritage',
  'bold-new': 'Bold (new)',
  bubble: 'Bubble',
  'retro-serif': 'Retro Serif',
  graphic: 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
};

export const fontsBags = {
  'all-sans': 'Carry All Sans Serif',
  'all-serif': 'Carry All Serif',
};

export const locations = {
  Top: 'Top',
  Side: 'Side',
  Back: 'Back',
};

export const TecLocations = {
  Left: 'Left',
  Right: 'Right',
};

export const TagLocations = {
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
};

export const Notebooklocations = {
  'center-landscape': 'center-landscape',
  'center-portrait': 'center-portrait',
  'bottom-left': 'bottom-left',
};

export default OrderPage;

// const LoadingContainer = props => <>Loading...</>;

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
//     LoadingContainer: LoadingContainer,
//     v: "3"
//   })(OrderPage)
// );
